import { GetStaticProps } from 'next'
import Link from 'next/link'
import React from 'react'

import Box from '../components/Box'
import TextBody from '../components/TextBody'

const NotFoundPage = () => {
  return (
    <Box>
      <TextBody>Page not found</TextBody>
      <TextBody>
        <Link href="/" scroll={false}>
          <a className="underline">Return home</a>
        </Link>
      </TextBody>
    </Box>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
    revalidate: 30, // seconds
  }
}

export default NotFoundPage
